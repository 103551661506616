import React from 'react';
import { Link } from 'react-router-dom';
import { FaAngleDown } from "react-icons/fa";
import ServiceData from "../../data/service/ServiceMain.json";
import { slugify } from '../../utils';

const getServiceData = ServiceData;

const Nav = () => {
    return (
        <nav className="mainmenu-nav">
            <ul className="mainmenu">
            <li><Link to={process.env.PUBLIC_URL + "/"}>Accueil</Link></li>
                <li className="menu-item-has-children">
                    <Link to="#">Services <FaAngleDown /></Link>
                    <ul className="axil-submenu">
                    <ul className="list-unstyled">
                        {getServiceData.slice(0, 6).map((data, index) => (
                            <li key={index}>
                                <Link to={process.env.PUBLIC_URL + `/service-details/${slugify(data.title)}`}>{data.title}</Link>
                             </li>
                     ))}
                    </ul>
                    </ul>
                </li>
                <li><Link to={process.env.PUBLIC_URL + "/about-us"}>A propos</Link></li>
                
                <li><Link to={process.env.PUBLIC_URL + "/contact"}>Contact</Link></li>
                <li><a href="https://app.Geoartech.com/">Login</a></li>
            </ul>
        </nav>
    )
}

export default Nav;