import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import SEO from '../common/SEO';


const TermsOfUse = () => {

    return (
        <>
            <SEO title="Conditions d'utilisation" />
            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BreadCrumbOne 
                title="Conditions d'utilisation"
                page="Conditions d'utilisation"
                />
                <div className="section-padding privacy-policy-area">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="privacy-policy-content">
                                <p>Ces conditions d'utilisation représentent l'accord entre vous et GEOARTECH Telematics. En acceptant ces conditions d'utilisation, vous acceptez également notre politique de confidentialité.</p>
                                <h4>Politique de confidentialité</h4>
                                <p>Nous comprenons que vous devez savoir comment nous protégeons votre vie privée. Notre politique de confidentialité est <a href={process.env.PUBLIC_URL + "/privacy-policy"}>disponible ici</a>.
                                </p>
                                <h4>Éligibilité</h4>
                                <p>Pour accéder ou utiliser les services fournis par GEOARTECH Telematics, vous devez être un représentant autorisé d'une entité légale ou être majeur selon la législation applicable. En acceptant ces conditions générales, vous assurez que les informations que vous fournissez sont exactes, complètes et toujours à jour. Des informations inexactes ou incomplètes peuvent entraîner la résiliation immédiate de votre compte via GEOARTECH Telematics. </p>
                                <h4>Confidentialité</h4>
                                <p>Vous êtes responsable de la confidentialité de votre compte et de votre mot de passe, notamment de la restriction de l'accès à votre ordinateur/appareil mobile et/ou à votre compte. Vous acceptez d'assumer la responsabilité de toutes les activités ou actions qui se produisent sous votre compte et/ou votre mot de passe. Nous n'assumons aucune responsabilité pour les pertes ou les dommages subis du fait que vous partagez vos informations de compte.<br></br><br></br>Vous devez informer immédiatement GEOARTECH Telematics de toute violation de sécurité ou d'achat non autorisé ou autre utilisation irrégulière de votre compte.</p>
                                <h4>Propriété intellectuelle</h4>
                                <p>GEOARTECH Telematics est, et sera, le seul et unique propriétaire de tous les titres, logos, marques de commerce et droits d'auteur affichés sur le site web et l'application mobile ou contenus dans les documents et autres matériels créés, fabriqués ou conçus dans le cadre de la prestation des services, y compris tous les droits de propriété intellectuelle y afférents (ci-après dénommés - propriété intellectuelle). Vous ne pouvez pas copier, reproduire, stocker, transmettre, diffuser, publier, modifier, créer une œuvre dérivée, afficher, exécuter, distribuer, redistribuer, vendre, concéder sous licence, louer, céder ou exploiter de quelque manière que ce soit toute propriété intellectuelle, en tout ou en partie, à des fins commerciales ou de quelque manière que ce soit qui ne soit pas conforme à ces conditions d'utilisation ou aux lois applicables sans l'autorisation écrite préalable de GEOARTECH Telematics. De plus, l'apparence du site web et de l'application mobile, notamment tous les en-têtes de page, graphismes personnalisés, icônes de boutons et scripts, est la marque de commerce de GEOARTECH Telematics et ne peut être copiée, imitée ou utilisée, en tout ou en partie, sans l'autorisation écrite préalable de GEOARTECH Telematics.<br></br><br></br>L'utilisation ou la mauvaise utilisation des marques de commerce ou d'autres propriétés intellectuelles de GEOARTECH Telematics, sauf autorisation expresse par ces conditions d'utilisation, est interdite. Vous devez informer rapidement GEOARTECH Telematics si vous reconnaissez ou suspectez que l'un des droits de propriété intellectuelle de GEOARTECH Telematics a été violé ou contrefait.</p>
                                <h4>Piratage de logiciel</h4>
                                <p>Le piratage de logiciel GEOARTECH Telematics est un vol. Si vous ou votre entreprise êtes pris en train de pirater le logiciel GEOARTECH Telematics, vous pourriez être tenu responsable en vertu du droit civil et pénal, payer une amende importante, être condamné à une peine de prison pouvant aller jusqu'à deux ans (ou les deux) conformément aux lois applicables.

Vous pouvez signaler anonymement le piratage de GEOARTECH Telematics par e-mail à l'adresse<a href="mailto:info@geoartech.com">info@geoartech.com</a> .</p>
                                <h4>Restrictions</h4>
                                <p>Les comptes de GEOARTECH Telematics ne sont pas transférables et les fonds ne sont pas remboursables pour les comptes actifs. GEOARTECH Telematics n'autorise pas le suivi caché. Vous ne pouvez pas utiliser nos services pour suivre une personne sans son autorisation ou un objet sans l'autorisation du propriétaire. Vous ne pouvez pas utiliser les services de GEOARTECH Telematics là où de telles actions sont illégales. Vous ne pouvez pas envoyer de demandes automatiques à GEOARTECH Telematics, essayer de tromper les mesures d'authentification de GEOARTECH Telematics, essayer d'ingénierie inverse notre logiciel ou nos protocoles et être impliqué dans des activités qui endommagent nos systèmes. Les comptes de GEOARTECH Telematics ne sont pas transférables, et les fonds ne sont pas remboursables pour les comptes actifs. Il est de votre responsabilité de vous assurer que votre utilisation de la localisation GPS ne viole pas les dispositions des législations pertinentes et vous acceptez d'assumer la responsabilité totale de toute violation des termes susmentionnés.</p>
                                <h4>Clause de non-responsabilité</h4>
                                <p>Votre utilisation des services est à vos propres risques.

Vous comprenez et acceptez que les services fournis par GEOARTECH Telematics sont fournis tels quels et tels que disponibles, sans garantie ou condition explicite ou implicite d'aucune sorte. GEOARTECH Telematics ne fait aucune représentation quant à la qualité satisfaisante des services et ne garantit pas que : i) les services seront de qualité satisfaisante, ininterrompus, sécurisés ou disponibles à un moment ou un endroit particulier ; ii) les services ne violeront pas les droits de tiers ; iii) les services seront compatibles avec tous les systèmes ; iv) le contenu du site web et de l'application mobile est exempt de virus ou d'autres contenus nuisibles ; v) les résultats de l'utilisation des services répondront à vos exigences.<br></br><br></br>Aucune partie du site web ou de l'application mobile n'a pour but de constituer un conseil et le contenu du site web ou de l'application mobile ne doit pas être utilisé comme base pour prendre des décisions ou prendre des mesures de quelque nature que ce soit.</p>
                                <h4>Indemnisation</h4>
                                <p>Vous acceptez de défendre, indemniser et tenir GEOARTECH Telematics, ses affiliés, directeurs, dirigeants et employés à l'abri, y compris les coûts et dépenses raisonnables d'avocats, de toute réclamation ou demande, y compris les honoraires d'avocats, de tiers découlant de : i) votre utilisation des services ; ii) votre violation de ces conditions d'utilisation ; iii) votre violation des droits de tiers.</p>
                                <h4>Résiliation</h4>
                                <p>GEOARTECH Telematics se réserve le droit de résilier votre compte, votre utilisation des services ou l'accès au site web et à l'application mobile à tout moment, sans préavis et sans responsabilité, pour tout motif, y compris, sans limitation, si GEOARTECH Telematics détermine, à sa seule discrétion, que vous avez violé ces conditions d'utilisation ou la législation applicable.</p>
                                <h4>Généralités</h4>
                                <p>Ces conditions d'utilisation constituent l'accord intégral entre vous et GEOARTECH Telematics et régissent votre utilisation des services, en remplacement de tous les accords antérieurs ou contemporains, écrits ou verbaux, entre vous et GEOARTECH Telematics. Si une disposition de ces conditions d'utilisation est jugée invalide ou inapplicable, cette disposition sera supprimée et les autres dispositions resteront en vigueur. Le non-exercice ou la non-application d'un droit ou d'une disposition de ces conditions d'utilisation ne constitue pas une renonciation à ce droit ou à cette disposition. Les titres des sections de ces conditions d'utilisation ne doivent pas être utilisés pour interpréter les dispositions contenues dans ces conditions d'utilisation. Ces conditions d'utilisation ne créent aucune relation de coentreprise, de partenariat, d'emploi ou d'agence entre vous et GEOARTECH Telematics.</p>
                                <h4>Modification des conditions d'utilisation</h4>
                                <p>GEOARTECH Telematics se réserve le droit de modifier ces conditions d'utilisation à tout moment et sans préavis. Les modifications prendront effet immédiatement après leur publication sur le site web et l'application mobile. Si vous continuez à utiliser les services après la publication de modifications, cela signifie que vous acceptez les modifications apportées aux conditions d'utilisation.</p>

                                
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <CtaLayoutOne />
            <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default TermsOfUse;