import React from 'react';
import SectionTitle from '../../elements/section-title/SectionTitle';
import TestimonialPropOne from './TestimonialPropOne';
import { FaLinkedinIn, FaFacebook, FaInstagram } from "react-icons/fa";

const TestimonialTwo = () => {
    return (
        <div className="section section-padding-equal">
            <div className="container">
                <SectionTitle 
                    subtitle="Recommandation"
                    title="Clients satisfaits"
                    description="Nous sommes engagés à fournir à nos clients des produits et services de qualité, et nos enquêtes de satisfaction client montrent que nos clients sont satisfaits de nos efforts."
                    textAlignment="heading-left"
                    textColor=""
                />
                <div className="row">
                    <TestimonialPropOne colSize="col-lg-4" itemShow="3"/>
                </div>
                <div className="axil-followers">
                    <ul className="followers-list list-unstyled">
                        <li className="dribble">
                            <a href="https://www.facebook.com/geoartech">
                                <span className="icon"><FaFacebook /></span>
                                <span>1.6k Followers</span>
                            </a>
                        </li>
                        <li className="behance">
                            <a href="https://www.instagram.com/geoartech">
                                <span className="icon"><FaInstagram /></span>
                                <span> 2k Followers</span>
                            </a>
                        </li>
                        <li className="linkedin">
                            <a href="https://www.linkedin.com/geoartech">
                                <span className="icon"><FaLinkedinIn /></span>
                                <span>3k Connections</span>
                            </a>
                        </li>
                    </ul>
                </div>

            </div>
            <ul className="shape-group-4 list-unstyled">
                <li className="shape-1">
                    <img src={process.env.PUBLIC_URL + "/images/LLC.webp"} alt="Bubble" />
                </li>
            </ul>
        </div>
    )
}

export default TestimonialTwo;