import React, { useState } from "react";
import GoogleMapReact from 'google-map-react';

const AnyReactComponent = ({ text, onClick }) => (
  <div onClick={onClick}>
    <img src={process.env.PUBLIC_URL + "/images/logo/logo.png"} alt="marker" width="68" height="68" />
  </div>
);

export default function SimpleMap(){
  const [zoom, setZoom] = useState(18); // Define state for the zoom level

  const defaultProps = {
    center: {
      lat: 35.54060622122809,
      lng: 6.184397343679602
    },
    zoom: zoom // Use the zoom state for the defaultZoom prop
  };

  const handleMarkerClick = () => {
    setZoom(20); // Update the zoom state when the marker is clicked
  };

  const options = {
    styles: [
      {
        featureType: "poi",
        elementType: "labels",
        stylers: [{ visibility: "on" }]
      },
      {
        featureType: "transit",
        elementType: "labels",
        stylers: [{ visibility: "on" }]
      },
      {
        featureType: "road",
        elementType: "labels",
        stylers: [{ visibility: "on" }]
      }
    ]
  };

  return (
    // Important! Always set the container height explicitly
    <div style={{ height: '60vh', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyAgFmRbEBc0Gw-3xYVEahIeM81K0djQBmE' }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        options={options} // Set the options prop
      >
        <AnyReactComponent
          lat={35.54060622122809}
          lng={6.184397343679602}
          text="Geoartech Telematics"
          onClick={handleMarkerClick} // Add event handler to the marker component
        />
      </GoogleMapReact>
    </div>
  );
}