import React from 'react';
import {useParams} from 'react-router-dom';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
//import BcrumbBannerOne from '../elements/breadcrumb/BcrumbBannerOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
//import SectionTitle from '../elements/section-title/SectionTitle';
//import ProjectPropOne from '../component/project/itemProp/ProjectPropOne';
import { slugify } from '../utils';
import ServiceData from "../data/service/ServiceMain.json";
//import ProjectData from "../data/project/ProjectData.json";
import ProcessOne from '../component/process/ProcessOne';
//import AboutTwo from '../component/about/AboutTwo';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import SEO from '../common/SEO';
import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';

const allServiceData = ServiceData;
//const getProjectData = ProjectData;

const ServiceDetails = () => {

    const params = useParams();
    const serviceSlug = params.slug;

    const getServiceData = allServiceData.filter(data => slugify(data.title) === serviceSlug);
    const detailsService = getServiceData[0];


    return (
        <>
        <SEO title="Services" />
        <ColorSwitcher />
        <main className="main-wrapper">
            <HeaderOne />
            <BreadCrumbOne 
            title={detailsService.title}
            paragraph ={detailsService.description}
            styleClass=""
            mainThumb="/images/banner/banner-thumb-4.png"
            />
            <img src={process.env.PUBLIC_URL + "/images/illustration.svg"} alt="illustration"></img>
           
            <ProcessOne />
            <img src={process.env.PUBLIC_URL + "/images/others/illustrationmain.svg"} alt="illustration"></img>
            <CtaLayoutOne />
        <FooterOne parentClass="" />
        </main>
        </>
    )
}

export default ServiceDetails;