import React from 'react';
import { Link } from 'react-router-dom';


const AboutFive = () => {
    return (
      <div className="section-padding-equal">
            <div className="container">
                <div className="row align-items-center">
                <div className="col-lg-7">
                    <div className="about-team">
                    <div className="thumbnail">
                        <img src={process.env.PUBLIC_URL + "/images/others/team.jpg"} alt="thumbnail" />
                    </div>
                    </div>
                </div>
                <div className="col-lg-5">
                    <div className="about-team">
                    <div className="section-heading heading-left">
                        <span className="subtitle">Notre Equipe</span>
                        <h2>Rencontrez notre équipe de gestion de flottes dédiée et expérimentée</h2>  
                        <p>Notre équipe d'experts en gestion de flottes a des années d'expérience dans le domaine et est capable de fournir des solutions sur mesure pour répondre aux besoins spécifiques de chaque entreprise.</p>
                        <Link to="#" className="axil-btn btn-large btn-fill-primary">Equipe</Link>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    )
}

export default AboutFive;