import React from 'react';
import FormOne from '../contact/FormOne';
import Accordion from 'react-bootstrap/Accordion';
//import { FaCompress, FaCode, FaGlobe } from 'react-icons/fa';


const AboutTwo = () => {
    return (
        
        <div className="section-padding">
            <div className="container">
                <img src={process.env.PUBLIC_URL + "/images/illustration.svg"} alt="illustration"></img>
                </div>
            <div className="container">
            <div className="row">
                    <div className="col-lg-6">
                        <div className="why-choose-us">
                            <div className="section-heading heading-left">
                                <span className="subtitle">données inégalées</span>
                                <h3 className="title">Conçu pour soutenir votre flotte avec une manière avancer</h3>
                                <p>Bienvenue sur l'appareil le plus puissant sur le marcher national. Le GOX1 est entièrement repensé, avec un processeur 32 bits, plus de mémoire, plus de RAM et un gyroscope plus battrie supplémentaire. Le GOX1 offre une capacité accrue pour une prise en charge supplémentaire des véhicules lourds, une meilleure prise en charge de l'utilisation du carburant.</p>
                            </div>
                            <Accordion defaultActiveKey="1">
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header><img src={process.env.PUBLIC_URL + "/images/icon/icon-52.svg"} alt="gps" />Suivi précis des véhicules</Accordion.Header>
                                    <Accordion.Body>
                                    Recueillez des données riches et précises sur l'emplacement du véhicule, la vitesse, la distance et la durée du trajet, la marche au ralenti du moteur et plus encore. Même si votre véhicule est garé à l'intérieur et sous terre, le suivi du véhicule par GPS et la télématique commencent à enregistrer dès que vous commencez à conduire. Le GOX1 présente le nouveau module GNSS (Global Navigation Satellite System) pour des temps de verrouillage plus rapides et des données de localisation de plus en plus précises.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header><img src={process.env.PUBLIC_URL + "/images/icon/icon-53.svg"} alt="gps" /> Évaluations de l'état du véhicule</Accordion.Header>
                                    <Accordion.Body>
                                    Extraire des informations précieuses sur l'état du véhicule. Enregistrez le Numéro de matriculation, l'odomètre, les défauts du moteur et plus encore. Ces données aident non seulement à prioriser l'entretien du véhicule, mais aident également à vérifier l'utilisation du véhicule pour identifier les habitudes de conduite sûres et à risque.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header><img src={process.env.PUBLIC_URL + "/images/icon/icon-54.svg"} alt="gps" /> Détection de collision</Accordion.Header>
                                    <Accordion.Body>
                                    Geoartech offre des données exceptionnelles sur les événements difficiles (accélération, freinage, virage, etc.) et la reconstruction des collisions grâce à nos algorithmes brevetés et à l'accéléromètre du dispositif GOX1. Si une collision suspectée est détectée, des données ECO-DRIVING détaillées seront automatiquement téléchargées à partir de l'appareil GOX1 pour permettre la reconstruction ECO-DRIVING de l'événement, y compris les collisions inversées dans le véhicule. Les alertes par e-mail et sur le bureau signalent le premier avis de perte.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="4">
                                    <Accordion.Header><img src={process.env.PUBLIC_URL + "/images/icon/icon-55.svg"} alt="gps" /> Données sécurisées</Accordion.Header>
                                    <Accordion.Body>
                                    Geoartech utilise l'authentification, le cryptage et la vérification de l'intégrité des messages pour les dispositifs de suivi des véhicules GOX1 et les interfaces réseau. Chaque appareil GOX1 utilise un identifiant unique et une clé de sécurité non statique, ce qui rend difficile la falsification de l'identité d'un appareil. Les mises à jour en direct utilisent un micrologiciel signé numériquement pour vérifier que les mises à jour proviennent d'une source fiable.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="5">
                                    <Accordion.Header><img src={process.env.PUBLIC_URL + "/images/icon/icon-57.svg"} alt="gps" /> Accompagnement de chauffeur en véhicule</Accordion.Header>
                                    <Accordion.Body>
                                    Améliorez les comportements de conduite, comme l'excès de vitesse et la marche au ralenti, en émettant une alerte sonore ou en accompagnant le conducteur avec des mots parlés (disponible en tant que module complémentaire). Une rétroaction immédiate peut améliorer la sécurité de la flotte, renforcer la politique de l'entreprise et encourager vos chauffeurs à prendre des mesures correctives immédiates.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="6">
                                    <Accordion.Header><img src={process.env.PUBLIC_URL + "/images/icon/icon-56.svg"} alt="gps" /> Capture de données avancée</Accordion.Header>
                                    <Accordion.Body>
                                    Les véhicules envoient des données à partir d'une multitude de sources, y compris le moteur, la transmission, le groupe d'instruments et d'autres sous-systèmes. Utilisant plusieurs réseaux internes, le GOX1 capture une grande partie de ces données.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </div>
                    <div className="col-xl-5 col-lg-6 offset-xl-1">
                        <div className="contact-form-box shadow-box mb--30">
                            <h3 className="title">Sécurisez dès maintenant votre véhicule!</h3>
                            <FormOne />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutTwo;