import React from 'react';
import { Link } from 'react-router-dom';
import { FaFacebookF, FaTwitter, FaLinkedin, FaInstagram } from "react-icons/fa";
import ServiceData from "../../data/service/ServiceMain.json";
import { slugify } from '../../utils';
import NewsletterForm from '../../component/contact/NewsletterForm';

const getServiceData = ServiceData;

const FooterOne = ({parentClass}) => {
    
    return (
        <footer className={`footer-area ${parentClass}`}>
            <div className="container">
                <div className="footer-top">
                    <div className="footer-social-link">
                        <ul className="list-unstyled">
                            <li><a href="https://facebook.com/geoartech"><FaFacebookF /></a></li>
                            <li><a href="https://twitter.com/geoartech"><FaTwitter /></a></li>
                            <li><a href="https://www.linkedin.com/geoartech"><FaLinkedin /></a></li>
                            <li><a href="https://www.instagram.com/geoartech"><FaInstagram /></a></li>
                        </ul>
                    </div>
                </div>
                <div className="footer-main">
                    <div className="row">
                        <div className="col-xl-6 col-lg-5">
                            <div className="footer-widget border-end">
                               <NewsletterForm/>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-7">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="footer-widget">
                                        <h6 className="widget-title">Services</h6>
                                        <div className="footer-menu-link">
                                            <ul className="list-unstyled">
                                                {getServiceData.slice(0, 6).map((data, index) => (
                                                    <li key={index}>
                                                        <Link to={process.env.PUBLIC_URL + `/service-details/${slugify(data.title)}`}>{data.title}</Link>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="footer-widget">
                                        <h6 className="widget-title">Documentation</h6>
                                        <div className="footer-menu-link">
                                            <ul className="list-unstyled">
                                                
                                                <li><Link to={process.env.PUBLIC_URL + "/"}>Présentation</Link></li>
                                                <li> <Link to={process.env.PUBLIC_URL + "/"}>Offre De service</Link></li>
                                                <li><Link to={process.env.PUBLIC_URL + "/"}>Portfolio</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="footer-widget">
                                        <h6 className="widget-title">Support</h6>
                                        <div className="footer-menu-link">
                                            <ul className="list-unstyled">
                                                <li><Link to={process.env.PUBLIC_URL + "/contact"}>Contact</Link></li>
                                                <li><Link to={process.env.PUBLIC_URL + "/"}>Sitemap</Link></li>
                                                <li> <Link to={process.env.PUBLIC_URL + "/privacy-policy"}>Politique de confidentialité</Link></li>
                                                <li><Link to={process.env.PUBLIC_URL + "/terms-use"}>Conditions d'utilisation</Link></li>
                                                
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom"> 
                    <div className="row">
                        <div className="col-md-6">
                            <div className="footer-copyright">
                                <span className="copyright-text">© {new Date().getFullYear()}. All rights reserved by <a href="https://Geoartech.com/">Geoartech Telematics</a>.</span>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="footer-bottom-link">
                                <ul className="list-unstyled">
                                <li><a href="https://app.Geoartech.com/">Login</a></li>
                                    <li><Link to={process.env.PUBLIC_URL + "/"}>Sitemap</Link></li>
                                    <li><Link to={process.env.PUBLIC_URL + "/privacy-policy"}>Politique de confidentialité</Link></li>
                                    <li><Link to={process.env.PUBLIC_URL + "/terms-use"}>Conditions d'utilisation</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default FooterOne;