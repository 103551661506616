import React, { useState } from 'react';
import { FaEnvelopeOpen } from "react-icons/fa";
import { toast, ToastContainer } from 'react-toastify';
import axios from 'axios';

const NewsletterForm = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!email) {
      return toast.error('Merci de remplir votre adresse e-mail');
    }

    try {
      setLoading(true);
      const response = await axios.post('/api/newsletter', { email });
      setLoading(false);
      toast.success(response.data.message);
      setEmail('');
    } catch (error) {
      setLoading(false);
      toast.error(
        error.response && error.response.data.message
          ? error.response.data.message
          : 'Une erreur s\'est produite. Veuillez réessayer.'
      );
    }
  };

  return (
    <form onSubmit={handleSubmit} className="footer-newsletter">
      <ToastContainer position='bottom-center' limit={1}/>    
      <h2 className="title">SUIVEZ NOUS</h2>
      <p>Obtenez toutes les dernières informations sur Notre Geoartech Telematics, les services et les offres. S'inscrire aux Newsletters:</p>
      <div className="input-group">
        <span className="mail-icon"><FaEnvelopeOpen /></span>
        <input type="email" className="form-control" placeholder="Adresse Email" name="email" value={email} onChange={handleEmailChange} required />
        <button className="subscribe-btn" type="submit" disabled={loading}>{loading ? 'Envoi...' : 'S\'ABONNER'}</button>
      </div>
    </form>
  );
};

export default NewsletterForm;