import React from 'react';
import SectionTitle from '../../elements/section-title/SectionTitle';

const Datas = [
    {
        id: 1,
        title: "Performance",
        para: "Rendement optimal afin de demeurer le premier choix de nos partenaires pour la fourniture et la mise en place de solutions de géolocalisation gps et de gestion de flotte."
    },
    {
        id: 2,
        title: "Fiabilité et Précision",
        para: "Geoartech Telematics s'engage à fournir des données exactes et des informations crédibles sur toutes nos publications: la fiabilité des résultats est l'élément fondamental de notre politique envers nos clients."
    },
    {
        id: 3,
        title: "Innovation",
        para: "La volonté permanente de progresser et de nous adapter aux plus récentes technologies."
    },
    {
        id: 4,
        title: "Action",
        para: "la seule source durable de potentiel concurrentiel, c’est la capacité de notre enterprise à agir d'une manière qui nous rend tous fiers."
    },
    {
        id: 5,
        title: "Dévloppement",
        para: "Construire une Platforme inclusive, solutions innovantes. Que vous possédiez un véhicule ou 1000, nous avons les solutions adaptées à vos besoins."
    }
]




const AboutThree = () => {
    return (
        <div className="section section-padding bg-color-dark pb--80 pb_lg--40 pb_md--20">
            <div className="container">
                <SectionTitle 
                    subtitle="Nos Valeurs"
                    title="Pourquoi travailler avec nous ?"
                    description="Geoartech Telematics fait progresser la sécurité, connecte les véhicules utilitaires à Internet et fournit des analyses basées sur le Web pour aider les clients à mieux gérer leurs flottes. La plateforme avancer de Geoartech Telematics permettent aux petites et grandes entreprises d'automatiser les opérations en intégrant les données des véhicules à leurs autres actifs de données."
                    textAlignment="heading-left heading-light-left mb--100"
                    textColor=""
                />
                

                <div className="row">
                    {Datas.map((data) => (
                        <div className="col-lg-4" key={data.id}>
                            <div className="about-quality">
                                <h3 className="sl-number">{data.id}</h3>
                                <h5 className="title">{data.title}</h5>
                                <p>{data.para}</p>
                            </div>
                        </div>
                    ))}

                </div>
            </div>
            <ul className="list-unstyled shape-group-10">
                <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/line-4.png"} alt="Circle" /></li>
                <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/bg1.png"} alt="Circle" /></li>
                <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/bg1.png"} alt="Circle" /></li>
            </ul>
        </div>
    )
}

export default AboutThree;