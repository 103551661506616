import React from 'react';
import SEO from '../common/SEO';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';
import FormTwo from '../component/contact/FormTwo';
import SectionTitle from '../elements/section-title/SectionTitle';
import ContactLocation from '../component/contact/ContactLocation';
import MapOne from '../component/contact/MapOne';

const Contact = () => {

  return (
    <>
      <SEO title="Blog Grid" />
      <ColorSwitcher />
      <main className="main-wrapper">
        <HeaderOne />
        <BreadCrumbOne title="Contact" page="Contact" />

        <div className="section section-padding">
          <div className="container">
            <div className="row">
              <div className="col-xl-5 col-lg-6">
                <div className="contact-form-box shadow-box mb--30">
                  <h3 className="title">Nous Contacter</h3>
                  <FormTwo />
                  <p>
                    Ce site est protégé par reCAPTCHA et la politique de
                    confidentialité et les conditions d'utilisation de Google
                    s'appliquent.
                  </p>
                </div>
              </div>
              <div className="col-xl-5 col-lg-6 offset-xl-1">
                <div className="contact-info mb--100 mb_md--30 mt_md--0 mt--150">
                  <h4 className="title">à votre disposition:</h4>
                  <p>JOURS & HEURES OUVRABLES Samedi- Jeudi & 9:00 - 17:00</p>
                  <h4 className="phone-number">
                    <a href="tel:+213540033285">0540 033 285</a>
                  </h4>
                  <h4 className="phone-number">
                    <a href="tel:+21360330920">0560 330 920</a>
                  </h4>
                  <h4 className="phone-number">
                    <a href="tel:+21333851374">033 85 13 74</a>
                  </h4>
                </div>
                <div className="contact-info mb--30">
                  <h4 className="title">Email</h4>
                  <p>
                    Notre équipe d'assistance vous répondra dans les 48 heures
                    pendant les heures ouvrables standard.
                  </p>
                  <h4 className="phone-number">
                    <a href="mailto:Contact@geoartech.com">
                      Info@geoartech.com
                    </a>
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <ul className="list-unstyled shape-group-12">
            <li className="shape shape-1">
              <img
                src={process.env.PUBLIC_URL + '/images/others/bubble-2.png'}
                alt="Bubble"
              />
            </li>
            <li className="shape shape-2">
              <img
                src={process.env.PUBLIC_URL + '/images/others/bubble-1.png'}
                alt="Bubble"
              />
            </li>
            <li className="shape shape-3">
              <img
                src={process.env.PUBLIC_URL + '/images/others/circle-3.png'}
                alt="Circle"
              />
            </li>
          </ul>
        </div>
        <div className="row">
          <div className="container">
            <MapOne/>
          </div>
        </div>
        <div className="section section-padding bg-color-dark overflow-hidden">
          <div className="container">
            <SectionTitle
              subtitle="Adresse"
              title="Notre Siège"
              description=""
              textAlignment="heading-light-left"
              textColor=""
            />
            <div className="row">
              <ContactLocation />
            </div>
          </div>
          <ul className="shape-group-11 list-unstyled"></ul>
        </div>

        <FooterOne parentClass="pt--150 pt_lg--100 pt_md--80 pt_sm--60" />
      </main>
    </>
  );
};

export default Contact;