import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
import AboutThree from '../component/about/AboutThree';
import AboutTwo from '../component/about/AboutTwo';
import SplashBanner from '../component/banner/SplashBanner';
import BrandOne from '../component/brand/BrandOne';
import CounterUpTwo from '../component/counterup/CounterUpTwo';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import PricingOne from '../component/pricing/PricingOne';
//import ProjectOne from '../component/project/ProjectOne';
import ServicePropOne from '../component/service/ServicePropOne';
import TestimonialTwo from '../component/testimonial/TestimonialTwo';
import SectionTitle from '../elements/section-title/SectionTitle';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';




const Home = () => {

    return (
        <>
        <SEO title="Geoartech Telematics | Fournisseur Gps En Algérie"/>
        <ColorSwitcher />
        <main className="main-wrapper">
            <HeaderOne />
            <SplashBanner />
            <div className="section section-padding-2 bg-color-dark">
                <div className="container">
                    
                    <SectionTitle 
                        subtitle="Services"
                        title="Obtenez une efficacité maximale, pour développer votre entreprise."
                        description="Restez maître du financement de votre parc automobile et externalisez-en la gestion à l'aide de Geoartech Telematics tout en analysant votre flotte pour optimiser les coûts.
                        La solution avancer Geoartech telematics vous libère de toutes les tâches administratives et sans valeur ajoutée pour prendre en charge l'ensemble des relations avec les fournisseurs et utilisateurs de votre parc automobile."
                        textAlignment="heading-light-left"
                        textColor=""
                    />
                    <div className='row'>
                        <ServicePropOne colSize="col-xl-4 col-md-6" serviceStyle="" itemShow="6" />
                    </div>
                </div>
                <ul className="list-unstyled shape-group-10">
                    <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/worldmap1.png"} alt="worldmap" /></li>
                    <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/bubble-42.png"} alt="Circle" /></li>
                    <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/bubble-43.png"} alt="Circle" /></li>
                </ul>
            </div>
            <div class="video-container">
  <center><video src={process.env.PUBLIC_URL + "/images/geobanner.mp4"} autoPlay muted loop alt="banner" /></center>
</div>
            <AboutTwo />
            <AboutThree />
            
            <CounterUpTwo />
            
            <div className="section bg-color-light section-padding">
                <div className="container">
                    <SectionTitle 
                        subtitle="Liste des Prix"
                        title="ALL IN ONE SOLUTION"
                        description="Tout ce dont vous avez besoin pour un transport durable et connecté,"
                        textAlignment=""
                        textColor=""
                    />
                    <PricingOne />
                </div>
                <ul className="list-unstyled shape-group-3">
                    <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/line-1.png"} alt="shape" /></li>
                    <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/bubble-4.png"} alt="shape" /></li>
                </ul>
            </div>
            <BrandOne />
            <TestimonialTwo />
            <CtaLayoutOne /> 
        <FooterOne parentClass="" />
        </main>
        </>
    )
}

export default Home;

