import React from 'react';
import { Link } from 'react-router-dom';
import CountUp from 'react-countup';
import TrackVisibility from 'react-on-screen';


const AboutFour = () => {
    return (
            <div className="section section-padding case-study-featured-area">
                <div className="container">
                    <div className="row">
                    <div className="col-xl-7 col-lg-6">
                        <div className="case-study-featured-thumb text-start">
                        <img src={process.env.PUBLIC_URL + "/images/others/coming-soon.png"} alt="travel" />
                        </div>
                    </div>
                    <div className="col-xl-5 col-lg-6">
                        <div className="case-study-featured">
                        <div className="section-heading heading-left">
                            <span className="subtitle">Geoartech Telematics</span>
                            <h2 className="title">Qui nous sommes ?</h2>
                            <p>Nous sommes ravis d'annoncer le lancement de Geoartech Telematics, une entreprise de gestion de flotte située en Algérie, dans la province de Batna. Nous sommes une équipe de professionnels dévoués qui se spécialisent dans l'aide aux entreprises pour gérer leurs flottes plus efficacement. Nos services incluent le suivi de flotte, l'analyse de flotte, la surveillance de flotte, l'optimisation de flotte, etc.</p>

                            <p>Nous comprenons à quel point il est important pour les entreprises que leurs flottes fonctionnent de la manière la plus fluide et la plus sûre possible. C'est pourquoi nous avons développé une puissante suite d'outils qui permettent aux entreprises de garder le contrôle de leurs flottes.</p>
                            <p>Nous utilisons les dernières technologies et techniques d'analyse de données pour nous assurer que nos clients obtiennent les informations les plus précises et les plus à jour sur leurs flottes. Nous fournissons également à nos clients des rapports et des informations personnalisés qui les aident à prendre les meilleures décisions pour leur entreprise.</p>

                            <p>Si vous êtes une entreprise dans la province d'Algérie qui cherche à optimiser les opérations de votre flotte, ne cherchez pas plus loin que Geoartech Telematics. Contactez-nous dès aujourd'hui pour plus d'informations sur nos services et comment nous pouvons vous aider à gérer votre flotte plus efficacement.</p>
                            <p>Sincèrement, Geoartech Telematics</p>
    
                            <Link to="../Contact" className="axil-btn btn-fill-primary btn-large">Contacter Nous</Link>
                        </div>
                        <div className="case-study-counterup">
                            <div className="single-counterup">
                            <h2 className="count-number">
                            <TrackVisibility once>
                                {({isVisible}) => (
                                    <span className="number count">
                                        {isVisible ? <CountUp end="8" duration={1} /> : null}
                                    </span>
                                )}  
                            </TrackVisibility>
                            <span className="symbol">+</span>
                            </h2>
                            <span className="counter-title">années sur le marché Algerien</span>
                            </div>
                            <div className="single-counterup">
                            <h2 className="count-number">
                            <TrackVisibility once>
                                {({isVisible}) => (
                                    <span className="number count">
                                        {isVisible ? <CountUp end="500" duration={1} /> : null}
                                    </span>
                                )}  
                            </TrackVisibility>
                            <span className="symbol">+</span>
                            </h2>
                            <span className="counter-title">Véhicules sécurisé</span>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default AboutFour;