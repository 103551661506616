import React from 'react';
import CountUp from 'react-countup';
import TrackVisibility from 'react-on-screen';

import Slider from "react-slick";

import SplashData from '../../data/splash/SplashData.json';
import Typewriter from 'typewriter-effect';


const DemoData = SplashData[0];

const CountData = [
    {
        id: 1,
        title: "Début D'activité",
        count_num: "2015"
    },
    {
        id: 2,
        title: "Clients Satisfaits",
        count_num: "130"
    },
    {
        id: 3,
        title: "Véhicules Sécurisés",
        count_num: "1810"
    }
]

var slideSettings = {
    infinite: true,
    speed: 1000,
    autoplaySpeed: 2500,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    centerMode: true,
    arrows: false,
    dots: false,
    swipeToSlide: true,
    draggable: false,
    responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 1,
            variableWidth: false
          }
        }
    ]
}

const SplashBanner = () => {
    return (
        <div className="section splash-main-banner">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-10">
                        
                        <div className="banner-content">
                            
                            <h1 className="title">Sécurisez Votre Flotte de Véhicule <span> <Typewriter
  options={{
    strings: ['NEW GPS System', 'En un Seul Click','Application Facile','Gps Euroupéen','Maintenant','أربح راحة بالك'],
    autoStart: true,
    loop: true,
  }}
/> </span></h1>
                            
                            <div className="site-element-count">
                                {
                                    CountData.map((data) => (
                                        <div className="count-box" key={data.id}>
                                            <span className="count-title">{data.title}</span>
                                            <div className="count-number h2">
                                                <TrackVisibility once>
                                                        {({isVisible}) => (
                                                            <span className="number count">
                                                                {isVisible ? <CountUp end={data.count_num} duration={2} /> : null}
                                                            </span>
                                                        )}  
                                                </TrackVisibility>
                                                <span className="symbol"></span>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="demo-slider">
                <Slider {...slideSettings} className="slick-dot-nav">
                    {DemoData.slice(0, 5).map((data) => (
                        <div className="single-slide" key={data.id}>
                           <a href="https://app.geoartech.com" target="_blank" rel="noopener noreferrer">
  <img src={`${process.env.PUBLIC_URL}${data.width_img}`} alt="platforme" />
</a>
                        </div>
                    ))}
                </Slider> 
            </div>

            <ul className="list-unstyled shape-group-20">
                <li className="shape shape-1">
                
                </li>
                
                <li className="shape shape-7">
                <img src={process.env.PUBLIC_URL + "/images/icon/wifi.gif"} alt="Bubble" />
                </li>
            </ul>
            
        </div>

    )
}

export default SplashBanner;