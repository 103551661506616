import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import SEO from '../common/SEO';


const PrivacyPolicy = () => {

    return (
        <>
            <SEO title="Politique de Confidentialité" />
            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BreadCrumbOne 
                title="Politique de Confidentialité"
                page="Politique de Confidentialité"
                />
                <div className="section-padding privacy-policy-area">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="privacy-policy-content">
                                <div className="section-title">
                                    <h5 className="title">Cette politique de confidentialité a été publiée le 10 janvier 2023.</h5>
                                </div>
                                <h4>Politique de Confidentialité</h4>
                                <p>Cette Politique de Confidentialité décrit le type d'informations que GEOARTECH Telematics peut collecter auprès des personnes utilisant ce site web et cette application mobile, la manière dont leurs données personnelles sont traitées, les droits des personnes concernées et d'autres informations pertinentes.<br></br><br></br>
                                En acceptant cette Politique de Confidentialité, vous acceptez que vos données personnelles soient collectées et traitées afin de fournir et d'améliorer les services sur notre site web ou notre application mobile. Le traitement de vos données personnelles est effectué en Algérie : Conformément à la loi 18-07 du 10-06-2018 relative à la protection des personnes physiques dans le traitement des données à caractère personnel.</p>
                                <h4>Données Personnelles</h4>
                                <p>Toute information qui, directement, indirectement ou en relation avec d'autres informations, se rapporte à une personne physique identifiée ou identifiable.</p>
                                <h4>Personne Concernée</h4>
                                <p>Toute personne dont les données personnelles sont collectées, détenues ou traitées.</p>
                                <h4>Responsable du Traitement</h4>
                                <p>L'entreprise qui détermine les finalités et les moyens de collecte et de traitement des données personnelles des personnes concernées - GEOARTECH Telematics, adresse : N° 59 AHMED MEDAIZA CITE ANNASR, BATNA, 05000, ALGERIA.</p>
                                <ul>
                                    <li>Entreprise Unipersonnelle à Responsabilité Limitée EURL GEOARTECH Telematics.</li>
                                    <li>Adresse postale : N° 32 Cité Z'mala en face les allées Mohamed Boudiaf "BANK NATIXIS", BATNA, 05000, ALGERIA.</li>
                                    <li>Numéro d'immatriculation au registre du commerce : R/C 05/00 – 0226037B19</li>
                                    <li>Numéro d'identification fiscale (NIF) : 001905022603787</li> 
                                    <li>Numéro d'identification statistique (NIS) : 001905010010080</li>
                                    <li>Adresse e-mail : support@geoartech.com</li>
                                    <li>service client : +213 33 85 13 74</li>
                                </ul>
                                <h4>Cloud</h4>
                                <p>L'endroit où toutes données personnelles sont stockées, gérées, sauvegardées à distance et mises à disposition des utilisateurs via un réseau.</p>
                                <h4>Finalités du Traitement de vos Données Personnelles</h4>
                                <p>GEOARTECH Telematics peut collecter, enregistrer et analyser vos données personnelles afin de :</p>

                                <ul>
                                    <li>faire fonctionner, surveiller, analyser et améliorer les services sur le site web ou l'application mobile.</li>
                                    <li>assurer la sécurité et la stabilité des services sur le site web ou l'application mobile</li>
                                    <li>vous fournir des notifications pour afficher des publicités et personnaliser d'autres contenus pour vous.</li>                                </ul>
                                <h4>Nous pouvons partager vos données personnelles avec des tiers uniquement s'il est nécessaire aux fins suivantes :</h4>
                                
                                <ul>
                                    <li>lorsque nous utilisons nos prestataires de services; en raison d'une demande de la part des autorités judiciaires ou nationales.</li>
                                    <li>Nous pouvons utiliser des services de tiers pour faciliter le paiement et la réalisation de vos achats.</li>
                                </ul>
                                <h4>Données Personnelles</h4>
                                <p>Nous ne collectons et ne traitons que les données personnelles que vous nous fournissez lors de votre inscription concernant l'utilisation des services sur le site web ou l'application mobile.

Si vous visitez notre site web ou notre application mobile, nous collectons les données suivantes, qui sont nécessaires pour afficher correctement le site web et garantir la stabilité et la sécurité requises :

adresse IP ;
statistiques de l'utilisateur - horodatage, différence de temps par rapport à GMT, emplacement général.
En outre, GEOARTECH Telematics peut collecter et traiter toute donnée personnelle que vous soumettez volontairement sur le site web ou l'application mobile, telles que :</p>
<ul>
                                    <li>votre nom complet, adresse, code postal, ville, région, pays, numéro de téléphone ;</li>
                                    <li>votre adresse e-mail, afin de recevoir des informations, des mises à jour et des bulletins d'information ;</li>
                                    <li>vos informations de paiement - type de carte de crédit, quatre derniers chiffres, date d'expiration ; informations de compte de paiement CIB ou autres pertinentes ; date de paiement, devise, montant.</li>
                                </ul>
                                <p>Vos données personnelles seront traitées uniquement aux fins identifiées ci-dessus. GEOARTECH Telematics ne procédera qu'aux actions de traitement de vos données personnelles qui sont nécessaires pour atteindre les finalités susmentionnées. Le traitement de vos données personnelles sera fondé sur la nécessité à l'exécution d'un contrat (article 6, paragraphe 1, point b) du Règlement général sur la protection des données). Toutes les autres actions de traitement de vos données personnelles seront fondées sur le consentement (article 6, paragraphe 1, point a) du Règlement général sur la protection des données) ou sur une autre base légale établie dans le Règlement général sur la protection des données.</p>
                                <h4>Données Personnelles et Cloud</h4>
                                <p className="mb--20">Si vous stockez ou traitez des données personnelles dans le Cloud en utilisant les services de GEOARTECH Telematics, vous êtes considéré comme un responsable de traitement et acceptez de prendre la pleine responsabilité de la conformité au Règlement général sur la protection des données et de la manière dont les données personnelles sont traitées et stockées dans le Cloud, et dans de tels cas, GEOARTECH Telematics n'est pas considéré comme le responsable de traitement ou le collecteur/traitant de vos données personnelles et des informations divulguées à cet effet.</p>
                        
                                <h4>Sécurité de vos Données Personnelles</h4>
                                <p className="mb--20">Afin de sécuriser vos données personnelles, nous appliquons les mesures de sécurité suivantes : exécution d'un logiciel de pare-feu, mise à jour régulière de nos serveurs avec les correctifs de sécurité, empêchement de l'accès aux Données Personnelles depuis l'extérieur et prise d'autres mesures nécessaires pour garantir la sécurité des données personnelles. Cependant, nous ne pouvons garantir la sécurité de vos données personnelles qui sont transmises via Internet</p>
                            
                                <h4>Consentement</h4>
                                <p>En utilisant notre site web ou notre application mobile, vous consentez à la collecte, au traitement et à l'utilisation de vos données personnelles telles que décrites dans cette Politique de Confidentialité. Si vous n'êtes pas d'accord avec cette Politique de Confidentialité, veuillez ne pas utiliser notre site web ou notre application mobile.</p>
                                <h4>Transfert de Données</h4>
                                <p>Vos données personnelles peuvent être transférées et traitées dans des pays autres que celui dans lequel vous résidez. Ces pays peuvent avoir des lois sur la protection des données différentes de celles de votre pays. Nous prendrons les mesures appropriées pour garantir que vos données personnelles soient adéquatement protégées conformément aux lois sur la protection des données applicables.</p>
                                <h4>Contactez-Nous</h4>
                                <p>Si vous avez des questions ou des préoccupations concernant cette Politique de Confidentialité ou nos pratiques relatives à vos données personnelles, veuillez nous contacter par e-mail à l'adresse <a href="mailto:contact@geoartech.com"> contact@geoartech.com </a> ou par courrier à l'adresse suivante : N° 32 Cité Z'mala en face les allées Mohamed Boudiaf "BANK NATIXIS", BATNA, ALGERIA. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <CtaLayoutOne />
            <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default PrivacyPolicy;