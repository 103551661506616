import React, {useState} from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";


const FormTwo = () => {

    const [contact, setContact] = useState('');
  const [enterprise, setEnterprise] = useState('');
  const [wilaya, setWilaya] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [text, setText] = useState('');
  const [loading, setLoading] = useState(false);
  const [captchaToken, setCaptchaToken] = useState('');
  const [selection, setSelection] = useState('privent');
  const [count, setCount] = useState('');

  const sendEmail = async (e) => {
    e.preventDefault();
    if (!contact || !wilaya || !email || !phone || !text || (selection === 'enterprise' && !enterprise && !count) || (selection === 'privent' && !count)) {
      return toast.error('Merci de remplir les informations');
    }
    try {
      setLoading(true);
      const { data } = await axios.post('/api/email', {
        contact,
        wilaya,
        enterprise,
        email,
        phone,
        count,
        captchaToken,
        
      });
      setLoading(false);
      toast.success(data.message);
    } catch (err) {
      setLoading(false);
      toast.error(
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message
      );
    }
  };

  const handleCaptchaChange = (value) => {
    setCaptchaToken(value);
  };

  const handleSelectionChange = (value) => {
    setSelection(value);
  };

  return (
    <form onSubmit={sendEmail} className="axil-contact-form">
      <ToastContainer position="bottom-center" limit={3} />
      
      <div className="form-group">
        <label>Nom &amp; Prénom</label>
        <input
          onChange={(e) => setContact(e.target.value)}
          type="text"
          className="form-control"
          name="contact"
          placeholder="Nom &amp; Prénom"
          required
        />
      </div>

      <div className="form-group">
        <label>Vous êtes</label>
        <select
          onChange={(e) => handleSelectionChange(e.target.value)}
          className="form-control"
          name="selection"
        >
          <option value="privent">Particulier</option>
          <option value="enterprise">Entreprise</option>
        </select>
      </div>
      
      {selection === 'enterprise' && (
        <div className="form-group">
          <label>Entreprise</label>
          <input
            onChange={(e) => setEnterprise(e.target.value)}
            type="text"
            className="form-control"
            name="enterprise"
            placeholder="Entreprise"
          />
          <input
            onChange={(e) => setCount(e.target.value)}
            type="tel"
            className="form-control"
            name="count"
            placeholder="Nombre de véhicules"
            required
          />
        </div>
      )}

      {selection === 'privent' && (
        <div className="form-group">
          <label>Nombre de véhicules</label>
          <input
            onChange={(e) => setCount(e.target.value)}
            type="tel"
            className="form-control"
            name="count"
            placeholder="Nombre de véhicules"
            required
          />
        </div>
      )}
      <div className="form-group">
      <label>Wilaya</label>
      <select
        onChange= {(e) => setWilaya(e.target.value)}
        className="form-control"
        name="wilaya"
        required
      >
        <option value="">Sélectionnez une wilaya</option>
        <option value="Adrar">Adrar</option>
        <option value="Chlef">Chlef</option>
        <option value="Laghouat">Laghouat</option>
        <option value="Oum El Bouaghi">Oum El Bouaghi</option>
        <option value="Batna">Batna</option>
        <option value="Béjaïa">Béjaïa</option>
        <option value="Biskra">Biskra</option>
        <option value="Béchar">Béchar</option>
        <option value="Blida">Blida</option>
        <option value="Bouira">Bouira</option>
        <option value="Tamanrasset">Tamanrasset</option>
        <option value="Tébessa">Tébessa</option>
        <option value="Tlemcen">Tlemcen</option>
        <option value="Tiaret">Tiaret</option>
        <option value="Tizi Ouzou">Tizi Ouzou</option>
        <option value="Alger">Alger</option>
        <option value="Djelfa">Djelfa</option>
        <option value="Jijel">Jijel</option>
        <option value="Sétif">Sétif</option>
        <option value="Saïda">Saïda</option>
        <option value="Skikda">Skikda</option>
        <option value="Sidi Bel Abbès">Sidi Bel Abbès</option>
        <option value="Annaba">Annaba</option>
        <option value="Guelma">Guelma</option>
        <option value="Constantine">Constantine</option>
        <option value="Médéa">Médéa</option>
        <option value="Mostaganem">Mostaganem</option>
        <option value="M'Sila">M'Sila</option>
        <option value="Mascara">Mascara</option>
        <option value="Ouargla">Ouargla</option>
        <option value="Oran">Oran</option>
        <option value="El Bayadh">El Bayadh</option>
        <option value="Illizi">Illizi</option>
        <option value="Bordj Bou Arréridj">Bordj Bou Arréridj</option>
        <option value="Boumerdès">Boumerdès</option>
        <option value="El Tarf">El Tarf</option>
        <option value="Tindouf">Tindouf</option>
        <option value="Tissemsilt">Tissemsilt</option>
        <option value="ElOued">El Oued</option>
        <option value="Khenchela">Khenchela</option>
        <option value="Souk Ahras">Souk Ahras</option>
        <option value="Tipaza">Tipaza</option>
        <option value="Mila">Mila</option>
        <option value="Aïn Defla">Aïn Defla</option>
        <option value="Naâma">Naâma</option>
        <option value="Aïn Témouchent">Aïn Témouchent</option>
        <option value="Ghardaïa">Ghardaïa</option>
        <option value="Relizane">Relizane</option>
        <option value="Timimoun ">Timimoun</option>
        <option value="Bordj Badji Mokhtar">Bordj Badji Mokhtar</option>
        <option value="Ouled Djellal">Ouled Djellal</option>
        <option value="Béni Abbès">Béni Abbès</option>
        <option value="In Salah">In Salah</option>
        <option value="In Guezzam">In Guezzam</option>
        <option value="Touggourt">Touggourt</option>
        <option value="Djanet">Djanet</option>
        <option value="El M'Ghair">El M'Ghair</option>
        <option value="El Meniaa">El Meniaa</option>
      </select>
    </div>

      <div className="form-group">
        <label>Email</label>
        <input
          onChange={(e) => setEmail(e.target.value)}
          type="email"
          className="form-control"
          name="email"
          placeholder="example@mail.com"
          required
        />
      </div>
      <div className="form-group mb--40">
        <label>Mobile</label>
        <input
          onChange={(e) => setPhone(e.target.value)}
          type="tel"
          className="form-control"
          name="phone"
          placeholder="+213550550555"
          required
        />
      </div>
      <div className="form-group mb--40">
            <label>Comment nous pouvons vous aidez</label>
            <textarea 
            onChange={(e) => setText(e.target.value)} className="form-control" name="contact-message" placeholder="Votre Message" rows="4"></textarea>
            
        </div>
      <div className="form-group">
        <ReCAPTCHA
          sitekey={'6LfsdfYlAAAAAOjYYZ3OpL8MDSeLFyDhyvEPfXi0'}
          onChange={handleCaptchaChange}
        />
      </div>
      <div className="form-group">
        <button
          disabled={loading}
          type="submit"
          className="axil-btn btn-fill-primary btn-fluid btn-primary"
          name="submit-btn"
        >
          {loading ? 'Envoie ... ' : 'Besoin d\'aide'}
        </button>
      </div>
      <div className="form-group"></div>
    </form>
  );
};

export default FormTwo;