import React from 'react';
import { Link } from 'react-router-dom';
import Offcanvas from 'react-bootstrap/Offcanvas'
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaInstagram, FaPhone, FaMobile } from "react-icons/fa";
const OffcanvasMenu = ({offcanvasShow, offcanvasHide}) => {
    return (
        <Offcanvas show={offcanvasShow} onHide={offcanvasHide} placement="end" className="header-offcanvasmenu">
            <Offcanvas.Header closeButton></Offcanvas.Header>
            <Offcanvas.Body>
                <form action="#" className="side-nav-search-form">
                    <div className="form-group">
                        <input type="text" className="search-field" name="search-field" placeholder="Search..." />
                        <button className="side-nav-search-btn"><i className="fas fa-search"></i></button>
                    </div>
                </form>
                <div className="row ">
                    <div className="col-lg-5 col-xl-6">
                        <ul className="main-navigation list-unstyled">
                            <li><Link to={process.env.PUBLIC_URL + "/"}>Accueil</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + "/services"}>Services</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + "/"}>A propos</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + "/"}>Contact</Link></li>
                            <li><a href="https://app.geoartech.com">Login</a></li>
                        </ul>
                    </div>
                    <div className="col-lg-7 col-xl-6">
                        <div className="contact-info-wrap">
                            <div className="contact-inner">
                                <address className="address">
                                    <span className="title">Contact Information</span>
                                    <p>N° 32 Cité Z'mala en face les allées Mohamed Boudiaf  <br /> "BANK NATIXIS", BATNA, ALGERIE</p>
                                </address>
                                <address className="address">
                                    <span className="title">JOURS & HEURES OUVRABLES Samedi <br /> - Jeudi & 9:00 - 17:00</span>
                                    <a href="tel:+21333851374" className="tel"><FaPhone /> +213 33 851 374</a>
                                    <a href="tel:+213540033285" className="tel"><FaMobile /> +213 540 033 285</a>
                                    <a href="tel:+213560330920" className="tel"><FaMobile /> +213 560 330 920</a>
                                </address>
                            </div>
                            <div className="contact-inner">
                                <h5 className="title"> Réseaux Sociaux</h5>
                                <div className="contact-social-share">
                                    <ul className="social-share list-unstyled">
                                        <li>
                                            <a href="https://facebook.com/geoartech"><FaFacebookF /></a>
                                        </li>

                                        <li>
                                            <a href="https://twitter.com/Geoartech_matic?s=09"><FaTwitter /></a>
                                        </li>
                                        <li>
                                            <a href="https://instagram.com/Geoartech"><FaInstagram /></a>
                                        </li>
                                        <li>
                                            <a href="https://www.linkedin.com/geoartech"><FaLinkedinIn /></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    )
}

export default OffcanvasMenu;
